import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ChevronArrow from '../../../images/arrow--caret-up.svg'
import ScheduleIcon from '../../../images/calendar-icon.svg'
import MaskIcon from '../../../images/colombina-mask-icon.svg'
import DiscoGlobeIcon from '../../../images/disco-globe-icon.svg'
import FoundationIcon from '../../../images/foundation-heart-icon.svg'
import HotelIcon from '../../../images/hotel-icon.svg'
import SpeakersIcon from '../../../images/lectern-icon.svg'
import FAQIcon from '../../../images/rectangle-speech-bubbles-icon.svg'
import RecognitionIcon from '../../../images/seint-trophy-icon.svg'
import TicketIcon from '../../../images/ticket-icon.svg'

const Wrapper = styled.div`
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .headline--reunion {
    margin-bottom: 10px;
  }

  .features {
    display: grid;
    grid-gap: 20px;
  }

  article {
    display: flex;
    flex-direction: column;

    .icon {
      margin-top: auto;
    }
  }

  .icon svg {
    margin: 25px auto;
  }

  .reunion-tickets {
    .icon svg {
      width: 112px;
    }
  }

  .reunion-venue {
    .icon svg {
      width: 121px;
    }
  }

  .reunion-schedule {
    .icon svg {
      width: 87px;
    }
  }

  .reunion-breakout-sessions {
    .icon svg {
      width: 107px;
    }
  }

  .reunion-classes {
    .icon svg {
      width: 101px;
    }
  }

  .reunion-parties {
    .icon svg {
      width: 104px;
    }
  }

  .reunion-speakers {
    .icon svg {
      width: 103px;
    }
  }

  .reunion-competition {
    .icon svg {
      width: 103px;
    }
  }

  .reunion-recognition {
    .icon svg {
      width: 75px;
    }
  }

  .reunion-faq {
    .icon svg {
      width: 110px;
    }
  }

  .btn--transparent {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .link-arrow {
    width: 11px;
    margin-left: -2px;
    transform: rotate(90deg);
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    .features {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 80px 100px;
    }

    article {
      .icon {
        display: inline-flex;
        justify-content: center;
        align-items: flex-end;
        height: 138px;
        flex-shrink: 0;
      }

      .icon svg {
        margin-top: 0;
      }

      .btn--transparent {
        flex: 1;
      }
    }
  }
`

const ReunionIndex = () => {
  return (
    <Wrapper className="wrap">
      <section className="section">
        <h2 className="headline--reunion">Reunion 2025</h2>
        <h2>Mark your calendars!</h2>
        <p>
          Reunion 2025 is coming to OKC on September 12-13, 2025<br />
          Same amazing crew, fresh new vibes.<br />
          Registration drops this summer—stay tuned!
        </p>
      </section>
      {/*
      <section className="section features">
        <article className="reunion-schedule">
          <Link to="/events/artist-reunion/schedule/" className="icon">
            <ScheduleIcon />
          </Link>
          <p className="title">Agenda</p>
          <p>
            Get ready for two days packed with in-depth training, breakout sessions taught by experts and fellow Artists, inspiring keynote speakers, surprise announcements, parties, and more.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/artist-reunion/schedule/"
          >
            View Schedule <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-tickets">
          <Link to="/events/artist-reunion/tickets/" className="icon">
            <TicketIcon />
          </Link>
          <p className="title">Tickets</p>
          <p>
            Secure your ticket now to the enchanting realm where dreams come true! Artists who register early will receive a discounted rate, extra raffle tickets, and exclusive incentives.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/artist-reunion/tickets/"
          >
            Register Now <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-breakout-sessions">
          <Link to="/events/artist-reunion/breakout-sessions/" className="icon">
            <BreakoutIcon />
          </Link>
          <p className="title">Breakout Sessions</p>
          <p>
            We’ve assembled the very best lineup of inspiring breakout sessions so that you can cater your Over the Rainbow experience to your specific needs and learn how to take the power already within to take your business to the next level.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/artist-reunion/breakout-sessions/"
          >
            View Sessions <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-classes">
          <Link to="/events/artist-reunion/classes/" className="icon">
            <MakeupIcon />
          </Link>
          <p className="title">Makeup Classes</p>
          <p>
            Learn hands on—in an intimate class setting—from some of our top makeup Artists! With a variety of makeup class options, get ready to elevate your application skills.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/artist-reunion/classes/"
          >
            View Classes <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-venue">
          <Link to="/events/artist-reunion/venue/" className="icon">
            <HotelIcon />
          </Link>
          <p className="title">Hotel</p>
          <p>
            &quot;It's not where you go, it's who you meet along the way.&quot; Stay at the host hotel, Signia by Hilton Atlanta, and mingle with old and new friends all weekend.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/artist-reunion/venue/"
          >
            Book Now <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-speakers">
          <Link to="/events/artist-reunion/speakers/" className="icon">
            <SpeakersIcon />
          </Link>
          <p className="title">Speakers</p>
          <p>
            We are excited to bring you this incredible lineup of speakers for this year's reunion!
            Each one brings a wealth of knowledge, passion, and inspiration to the stage, here to
            empower you with insights and tips that will elevate your artistry and business. Get ready
            to be inspired, learn, and connect with these amazing speakers, who are dedicated to helping
            you succeed!
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/artist-reunion/speakers/"
          >
            Learn More <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-parties">
          <Link to={`/events/artist-reunion/parties/`} className="icon">
            <DiscoGlobeIcon />
          </Link>
          <p className="title">Parties</p>
          <p>
            Stroll through the enchanted land of Oz at the Over the Rainbow parties!
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to={`/events/artist-reunion/parties/`}
          >
            View Parties <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-recognition">
          <Link to={`/events/artist-reunion/recognition/`} className="icon">
            <RecognitionIcon />
          </Link>
          <p className="title">The Seint Awards</p>
          <p>
            Over the Rainbow is our opportunity to applaud you and your amazing contributions to Seint. Check out the Artist accomplishments we will recognize this year!
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to={`/events/artist-reunion/recognition/`}
          >
            Learn More <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-competition">
          <Link to="/events/artist-reunion/competition/" className="icon">
            <MaskIcon />
          </Link>
          <p className="title">Seint Glow-Up Competition</p>
          <p>
            Get ready for Season 4 of Seint’s Glow-Up Competition! We loved seeing the creativity and artistry
            during our last Glow-Up Competition, and we can’t wait to see you bring the heat for this year’s
            showdown!
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/artist-reunion/competition/"
          >
            Learn More <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-speakers">
          <Link to="/events/artist-reunion/events/" className="icon">
            <FoundationIcon />
          </Link>
          <p className="title">Love Is Never Wasted Foundation</p>
          <p>
            Help us make a difference in the lives of foster children right here in Atlanta! This year, we’ve partnered with the Atlanta Angels, who walk alongside children, youth, and families in the foster care community by offering consistent support through intentional giving, relationship building, and mentorship.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/artist-reunion/events/"
          >
            Learn More <ChevronArrow className="link-arrow" />
          </Link>
        </article>
        <article className="reunion-faq">
          <Link to="/events/artist-reunion/policies-and-faq/" className="icon">
            <FAQIcon />
          </Link>
          <p className="title">Policies & FAQ</p>
          <p>
            Questions? Check out the Policies and FAQs for more information regarding Over the Rainbow.
          </p>
          <Link
            className="btn--transparent btn--with-icon"
            to="/events/artist-reunion/policies-and-faq/"
          >
            Read Here <ChevronArrow className="link-arrow" />
          </Link>
        </article>
      </section>
      */}
    </Wrapper >
  )
}

export default ReunionIndex
